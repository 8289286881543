import { NextSeo } from 'next-seo';
import React from 'react';

const HomeSeo = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
    <NextSeo
  title="YourCad: Affordable Autodesk & AutoCAD Design Solutions"
  description="Discover affordable Autodesk and AutoCAD solutions with YourCad. Unleash precision, innovative design, and budget-friendly Autodesk solutions tailored for design success."
  canonical="https://yourcad.store"
  openGraph={{
    type: 'website',
    url: 'https://yourcad.store',
    title: 'Affordable Autodesk and AutoCAD Solutions for Design Success',
    description:
      'Discover affordable Autodesk and AutoCAD solutions with YourCad. Unleash precision, innovative design, and budget-friendly Autodesk solutions tailored for design success.',
    images: [
      {
        url: '/seoImg.webp',
        width: 3200,
        height: 1920,
        alt: 'YourCad logo showcasing Autodesk solutions',
        type: 'image/webp',
      },
    ],
    site_name: 'Home - YourCad',
  }}
  twitter={{
    cardType: 'summary_large_image',
    site: '@yourcad',
    title: 'Affordable Autodesk and AutoCAD Solutions for Design Success',
    description:
      'Discover affordable Autodesk and AutoCAD solutions with YourCad. Unleash precision, innovative design, and budget-friendly Autodesk solutions tailored for design success.',
    image: '/seoImg.webp',
  }}
  additionalMetaTags={[
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    { name: 'application-name', content: 'YourCAD Store' },
    { name: 'robots', content: 'index, follow' },
    {
      name: 'keywords',
      content: 'YourCad, Autodesk, AutoCAD, Autodesk solutions, design services, budget-friendly design, precision design, affordable services, AutoCAD LT, Autodesk design tools, AutoCAD architecture, CAD software, engineering design tools, AutoCAD 3D, Autodesk licenses, design software, affordable CAD, AutoCAD for beginners, AutoCAD for professionals, Autodesk tools, AutoCAD licenses, CAD solutions, drafting software, Autodesk for architects, CAD tools for engineers, 3D modeling software, AutoCAD for PC, AutoCAD subscription, AutoCAD trial, AutoCAD plugins, architecture software, civil 3D, AutoCAD Civil 3D, AutoCAD customization, engineering software, Autodesk training, AutoCAD online, AutoCAD for mac, CAD for mac, AutoCAD building design, Autodesk BIM, AutoCAD Revit, Autodesk Revit, AutoCAD pricing, AutoCAD cost, CAD courses, CAD tutorials, CAD online tools, AutoCAD support, Autodesk account, Autodesk student license, AutoCAD student discount, CAD programs, drafting tools, AutoCAD drafting, CAD software for engineers, CAD software for architects, architectural design tools, AutoCAD license key, AutoCAD 1-year subscription, CAD productivity, Autodesk construction, BIM design, Autodesk Fusion 360, CAD training, AutoCAD web, AutoCAD mechanical, precision CAD, Autodesk software, AutoCAD cost comparison, 2D CAD, 3D CAD, CAD drawing tools, Autodesk portfolio, AutoCAD resources, Autodesk consulting, design solutions, AutoCAD educational licenses, CAD for construction, drafting solutions, CAD innovations, Autodesk architecture, design success, AutoCAD vs Revit, 3D CAD modeling, CAD industry tools, affordable Autodesk, AutoCAD for infrastructure, CAD optimization, drafting and design tools, design visualization, CAD BIM integration, Autodesk solutions for engineers, AutoCAD LT features, CAD building modeling, CAD top tools, AutoCAD training resources, AutoCAD student software'
    },    
    { name: 'author', content: 'YourCad, support@yourcad.store' },
    { name: 'copyright', content: `YourCad © ${new Date().getFullYear()}` },
    { name: 'subject', content: 'Affordable Autodesk and AutoCAD Solutions for YourCad' },
    { name: 'distribution', content: 'Global' },
    { name: 'coverage', content: 'Worldwide' },
    { name: 'rating', content: 'General' },
    { name: 'HandheldFriendly', content: 'True' },
    { name: 'MobileOptimized', content: '320' },
    { name: 'url', content: 'https://yourcad.store' },
    { name: 'identifier-URL', content: 'https://yourcad.store' },
  ]}
/>

      {/* JSON-LD Structured Data */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "YourCad",
            url: "https://yourcad.store",
            logo: "https://yourcad.store/seoImg.webp",
            // sameAs: [
            //   "https://www.facebook.com/yourcad",
            //   "https://www.twitter.com/yourcad",
            //   "https://www.linkedin.com/company/yourcad",
            // ],
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+1-800-123-4567",
              contactType: "Customer Service",
              email: "support@yourcad.store",
            },
            description:
              "Affordable Autodesk Services for Budget-Friendly Design Success",
            address: {
              "@type": "PostalAddress",
              streetAddress: "123 YourCad Street",
              addressLocality: "YourCity",
              postalCode: "",
              addressCountry: "",
            },
          }),
        }}
      />
    </>
  );
};

export default HomeSeo;
